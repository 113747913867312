import { graphql, PageProps } from 'gatsby';

import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';

type ClientPageProps = PageProps<{
  clients: any;
}>;

const ClientsPage = ({ data }: ClientPageProps) => {
  return (
    <Layout>
      <Seo title={'Home'} />
      <div className='page-clients'>
        <h1>Clients</h1>
        <div className='listing'>
          {data.clients.nodes.map((client: any) => (
            <div key={client.id}>
              <p>{client.title}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ClientsPage;

export const query = graphql`
  query ClientsPage {
    clients: allStrapiClient(sort: { fields: [title] }) {
      nodes {
        id
        title
      }
    }
  }
`;
